/* eslint-disable react/display-name */
import { m, useTransform } from 'framer-motion';
import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';
// import loadable from '@loadable/component';

import { FluidImg } from 'src/embeds/image/image-dato';
import { Stack } from 'src/system2/stack';
import { Carousel } from 'src/primitives/carousel';
// import { useBreakpoints } from 'src/hooks/use-breakpoints';
import Dots from 'src/patterns/dots';
import * as sys from '../../system';
import * as css from './drawer-testimonials.css';

const Avatar = styled.div`
  ${css.avatar}
`;
const Content = styled(Stack)`
  ${css.contentStack}
`;

const TestSlide = React.forwardRef(function TestSlide(
  {
    quoteTextNode,
    personName,
    personTitle,
    personImage,
    customer,
    motionValues: { slidePosition, slideProgress, carouselProgress, carouselVelocity },
  },
  fwdRef,
) {
  const patternOffset = useTransform(slidePosition, (x) => x / 2);
  const quoteOffset = useTransform(slidePosition, (x) => x / -8);
  const offsetOpacity = useTransform(slideProgress, (p) => 1 - Math.abs(p * 2));

  return (
    <figure css={css.slide} ref={fwdRef}>
      <div css={css.stop}>
        <div css={css.flowGrid}>
          {personImage &&
            <div style={{ flex: '0 0 auto' }}>
              <Avatar>
                <FluidImg data={personImage} maxWidth={220} />
              </Avatar>
            </div>}
          <Content sx={{ gapY: { _: 30 } }}>
            <blockquote
              css={css.quoteText}
              cite={customer && customer.url}
              dangerouslySetInnerHTML={{ __html: quoteTextNode.childMarkdownRemark.html }}
            />
            <figcaption css={css.captionText}>
              <strong>{personName}</strong>
              <br />
              {personTitle}
              {customer ? (
                <span>
                  {' @ '}
                  <cite>{customer.name}</cite>
                </span>
              ) : null}
            </figcaption>
          </Content>
        </div>
      </div>
      <m.div style={{ x: patternOffset, opacity: offsetOpacity }} css={css.pattern}>
        <Dots fill="#e0e0fb" width={400} />
      </m.div>
      <m.div style={{ x: quoteOffset, opacity: offsetOpacity }} css={css.quote}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 135 108">
          <path
            fill="#E0E0FA"
            d="M0 55.013L23.994 0h40.47l-40.18 55.013h24.86V108H0V55.013zM94.529 0H135L94.818 55.013h24.861V108H70.535V55.013L94.53 0z"
          ></path>
        </svg>
      </m.div>
    </figure>
  );
});

export default function Testimonials({ callouts, content, contentNode, customData, heading }) {
  const quotes = callouts.filter(({ __typename }) => __typename === 'DatoCmsTestimonialBlock');
  customData = JSON.parse(customData);

  return (
    <section css={[css.section]} className="reset2">
      <div css={[sys.wrapLast, sys.frame]}>
        {heading && <h2 css={[css.sectionHeading]}>{heading}</h2>}
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        <div css={[css.frame]}>
          <Carousel
            matchMedia="<50em"
            flickityOptions={{
              prevNextButtons: Boolean(customData?.flags?.prevNextButtons),
              pageDots: false,
              wrapAround: true
            }}
          >
            {quotes.map((quote) => (
              <TestSlide key={quote.personName} {...quote} />
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
