import React from 'react';

function Dots({ fill = '#e0e0fb', size = 40, radius = 4, width = 300, height = 150 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern id="kwvp0u" width={size} height={size} patternUnits="userSpaceOnUse">
          <circle
            cx={radius + size / 2}
            cy={radius + size / 2}
            r={radius}
            fill={fill}
            strokeWidth="0"
          ></circle>
          <circle cx={radius + 0} cy={radius + 0} r={radius} fill={fill} strokeWidth="0"></circle>
          <circle cx={radius + 0} cy={radius + size} r={radius} fill={fill} strokeWidth="0"></circle>
          <circle cx={radius + size} cy={radius + size} r={radius} fill={fill} strokeWidth="0"></circle>
          <circle cx={radius + size} cy={radius + 0} r={radius} fill={fill} strokeWidth="0"></circle>
        </pattern>
      </defs>
      <rect fill="url(#kwvp0u)" width={width} height={height} />
      {/* <path fill="url(#kwvp0u)" d={`M0 0H${width}V${height}H0z`}></path> */}
    </svg>
  );
}

export default Dots;
